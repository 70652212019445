export const loginInitialValues = {
  email: '',
  password: '',
};

export const signupInitialValues = userDetails => {
  const { firstName, lastName, email } = userDetails;

  return {
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    email: email ? email : '',
    password: '',
    changePassword: '',
    acceptTerms: false,
  };
};

export const additionalSignupInformationInitialValues = phoneNumber => {
  return {
    phoneNumber: phoneNumber || '',
  };
};

export const phoneNumberVerificationInitialValues = () => {
  return { phoneNumber: '' };
};
