import React from 'react';
import moment from 'moment';

import {
  kycStatuses,
  operationType as OperationType,
} from '../../../../helpers/constants';
import { capitalizeTheFirstLetterOfEachWord } from '../../../../helpers/investmentHelpers';

const formattedDate = date =>
  date ? moment(date).format('ddd, Do YYYY, h:mm A') : '---';

export const getTimelineSteps = (timeline, kycRejectionReason) => {
  const {
    createdAt,
    archived,
    archivedReason,
    reconciled,
    reconciledAt,
    displayKycVerification,
    refunded,
    completed,
    refundedAt,
    kycStatus,
    operationType,
  } = timeline;

  const isDeposit = operationType === OperationType.DEPOSIT;

  const kycStatusesForDisplayingStartVerificationButton = [
    kycStatuses.PENDING,
    kycStatuses.REJECTED,
    kycStatuses.IN_EDIT,
  ];

  const kycStatusDescription = {
    [kycStatuses.PENDING]: {
      description: 'Confirm your Identity to start earning',
      buttonText: 'Start Verification',
      descriptionColor: 'text-primary-orange',
    },
    [kycStatuses.SUBMITTED]: {
      description:
        'Your submission is under review, this may take up to 48 hours',
      descriptionColor: 'text-primary-orange',
    },
    [kycStatuses.REJECTED]: {
      description: kycRejectionReason,
      buttonText: 'Complete Verification',
      descriptionColor: 'text-red-600',
    },

    [kycStatuses.IN_EDIT]: {
      description: 'Update your information to continue earning.',
      buttonText: 'Complete Verification',
      descriptionColor: 'text-primary-orange',
    },
  };

  const informationIconCopy = isDeposit ? (
    <span>
      All funds and investments are held in a{' '}
      <span className="font-weight-600">Custodian Bank</span>, ensuring your
      assets stay safe. Investment reconciliation involves verifying and
      matching your payment details with our records to ensure your funds are
      correctly allocated.
    </span>
  ) : (
    <span>
      The 3-day timeline for disinvestment is due to standard processes that
      ensure accuracy and compliance. This includes verifying your request,
      adhering to the financial market’s T+2 settlement cycle, and processing
      payments securely to your bank account. These steps are essential to
      protect your funds and ensure a smooth transaction. If you have any
      questions, feel free to reach out at{' '}
      <span className="text-blue font-weight-600">support@ndovu.com.</span>
    </span>
  );

  return [
    {
      iconName: 'tick-circle',
      title: isDeposit ? 'Money Deposited' : 'Disinvestment Initiated',
      description: formattedDate(createdAt),
      competed: true,
      descriptionTextColor: 'text-dark-gray-300',
    },

    {
      iconName: archived
        ? 'cross-red-circle'
        : reconciled
        ? 'tick-circle'
        : 'circle',
      title: archived
        ? 'Transaction Error'
        : isDeposit
        ? 'Deposit Received'
        : 'Request Processing',
      description: reconciled ? (
        formattedDate(reconciledAt)
      ) : archived ? (
        <span>
          {`${capitalizeTheFirstLetterOfEachWord(
            archivedReason?.replaceAll('_', ' '),
          )}`}
          . Contact{' '}
          <span className="text-blue font-weight-600">support@ndovu.co</span>
        </span>
      ) : isDeposit ? (
        'Processing time: Mpesa-1 day, Bank-3 days'
      ) : (
        'Your disinvestment request is being processed, may take up to 2 days'
      ),
      competed: archived || reconciled,
      informationIconName: archived ? 'message-question' : 'info-circle',
      iconOpensChatWidget: archived,
      descriptionTextColor:
        archived || reconciled ? 'text-dark-gray-300' : 'text-primary-orange',
      informationIconCopy,
    },

    displayKycVerification && {
      iconName: 'circle',
      title: 'Account Verification',
      description: kycStatusDescription[kycStatus].description,
      addButton: kycStatusesForDisplayingStartVerificationButton.includes(
        kycStatus,
      ),
      buttonText: kycStatusDescription[kycStatus]?.buttonText,
      descriptionTextColor: kycStatusDescription[kycStatus]?.descriptionColor,
    },

    (!archived || completed || refunded) && {
      iconName: completed || refunded ? 'tick-circle' : 'circle',
      title: refunded
        ? 'Refunded'
        : isDeposit
        ? 'Investment Complete'
        : 'Money Transferred to Pocket',
      description:
        completed || refunded
          ? formattedDate(refundedAt)
          : isDeposit
          ? 'The investment process will complete after the steps above are met'
          : 'The disinvestment process will complete after the steps above are met',
      competed: completed || refunded,
      descriptionTextColor:
        completed || refunded ? 'text-dark-gray-300' : 'text-primary-orange',
    },
  ].filter(Boolean);
};
