import { motion } from 'framer-motion/dist/framer-motion';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

import { Titles } from '../../Common/Titles';
import { DisplayImage } from '../../Common/DisplayImage';

const Promo = ({ plans }) => {
  const history = useHistory();

  const handleHotFundClick = planId => {
    trackEventMixpanel('Click_Hot_Fund');
    localStorage.setItem('planEntryPointUrl', '/home');
    history.push(`/plans/${planId}`);
  };

  return (
    <motion.div
      className={plans?.length > 0 ? 'mt-10' : 'd-none'}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        delay: 0.8,
        scale: {
          type: 'spring',
          damping: 15,
          stiffness: 50,
          restDelta: 0.001,
        },
      }}
    >
      <Titles title={'Hot Funds'} />
      <div className="fund-plans">
        {plans?.length > 0 &&
          plans.map(plan => {
            return (
              <div onClick={() => handleHotFundClick(plan?.id)} key={plan?.id}>
                <div className="promo-card overflow-hidden" key={plan.id}>
                  <DisplayImage
                    alt="PromoImage"
                    src={plan.offerAssetName || plan.imageUrl}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </motion.div>
  );
};

export { Promo };
