import React, { useState } from 'react';

export const DisplayImage = ({ src, alt }) => {
  const [isError, setIsError] = useState(false);

  return isError ? (
    <div className="bg-gray-500 w-100 h-100"></div>
  ) : (
    <img
      style={{ width: '100%', height: '100%' }}
      src={src}
      alt={alt}
      onError={() => setIsError(true)}
    />
  );
};
