import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ConfirmButton } from '../../../Common/ReusableButtons';
import { RiskLevel } from '../RiskLevel';
import { DisplayImage } from '../../../Common/DisplayImage';

export const FundDetailsHeaderSection = ({
  planDetails,
  generateButtonText,
  handleInvest,
  openTermsAndConditionModal,
  disableInvestButton,
  investBtnIcon,
  termsAndConditionsRequired,
}) => {
  const smallDevice = useMediaQuery('(max-width : 768px)');

  return (
    <div className="mb-5">
      <div
        className={`d-flex ${
          smallDevice ? 'flex-column' : 'flex-row'
        } justify-content-between mb-10`}
      >
        {/* Left side start*/}
        <div className={`d-flex ${smallDevice ? 'align-items-center' : ''}`}>
          <div className="fund-thumbnails overflow-hidden bg-gray-500">
            <DisplayImage
              src={planDetails.thumbnail}
              alt={planDetails.coolName}
            />
          </div>

          <div
            className={`d-flex justify-content-between ml-5 ${
              smallDevice ? 'flex-grow-1' : 'flex-column'
            }`}
          >
            <div
              className={`${
                smallDevice ? 'd-flex justify-content-between flex-grow-1' : ''
              }`}
            >
              <div
                className={`font-weight-600 text-dark-blue-800 ${
                  smallDevice ? 'text-16' : 'text-23 mb-2'
                }`}
              >
                {planDetails?.coolName}
              </div>
              <div className="d-flex flex-column">
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`pr-2 font-weight-500 ${
                      smallDevice ? 'text-15' : 'text-13'
                    } text-dark-350`}
                  >
                    {planDetails?.fundCurrency?.currency}
                  </div>
                  <img
                    className="currency-flag"
                    src={planDetails.fundCurrency?.currencyFlag}
                    alt="country flag"
                  />
                </div>
              </div>
            </div>
            {!smallDevice ? (
              <ConfirmButton
                buttonText={generateButtonText()}
                handleOnClick={() =>
                  termsAndConditionsRequired && !planDetails?.isInvested
                    ? openTermsAndConditionModal()
                    : handleInvest('Button_2')
                }
                disable={disableInvestButton}
                buttonIcon={investBtnIcon}
                buttonWidth={'button-w-265'}
                classes={'d-flex justify-content-center align-items-center'}
              />
            ) : null}
          </div>
        </div>
        {/* Left side end*/}

        {/* Right side start*/}
        <div
          className={`d-flex justify-content-between ${
            !smallDevice ? 'flex-column' : 'mt-4'
          }`}
        >
          <div className={`${smallDevice ? 'text-left' : 'text-right'}`}>
            <div
              className={`${
                smallDevice ? 'text-16' : 'text-23'
              } font-weight-600 text-dark-blue-800`}
            >
              {planDetails?.performance1 > 0 ? (
                <span>+{Math.abs(planDetails?.performance1)}%</span>
              ) : planDetails?.performance1 < 0 ? (
                <span>-{Math.abs(planDetails?.performance1)}%</span>
              ) : (
                <span>---</span>
              )}
            </div>
            {planDetails?.profitTypeDescription ? (
              <div
                className={`text-dark-350 ${
                  smallDevice ? 'text-13' : 'text-14'
                } mt-n1`}
              >
                {planDetails?.profitTypeDescription}
              </div>
            ) : null}
          </div>

          <RiskLevel
            planRiskLevel={planDetails?.planRiskLevel}
            squareSize={smallDevice ? '12px' : '20px'}
            extremelyLowSquareWidth={smallDevice ? '6px' : '10px'}
            riskLevelFontSize={smallDevice ? 'text-13' : 'text-14'}
          />
        </div>
        {/* Right side start*/}
      </div>

      {smallDevice ? (
        <ConfirmButton
          buttonText={generateButtonText()}
          handleOnClick={() =>
            termsAndConditionsRequired && !planDetails?.isInvested
              ? openTermsAndConditionModal()
              : handleInvest('Button_1')
          }
          disable={disableInvestButton}
          buttonIcon={investBtnIcon}
          buttonWidth={'w-100'}
          classes={`d-flex justify-content-center align-items-center ${
            smallDevice ? 'mt-n6' : ''
          }`}
        />
      ) : null}
    </div>
  );
};
