import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { errorMessages, googleAuth } from '../../../helpers/constants';
import * as profile from '../../Profile/_redux/profileRedux';
import '../styles/registration.scss';
import { login } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import { getInputClasses } from '../../../helpers/getInputClasses';
import CustomInput from '../../Common/CustomInput';
import { PasswordInput } from '../../Common/PasswordInput';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { loginSchema } from '../Schemas';
import { loginInitialValues } from '../InitialValues';
import { ConfirmButton, CustomButton } from '../../Common/ReusableButtons';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';
import { handleGoogleSignUpAndSignIn } from '../../../helpers/googleAuth';
import { updateLoginState } from '../helpers/udpateLoginState';
import { cookiesDisabled } from '../helpers/cookiesDisabled';
import { clearLocalStorageOnLogout } from './Logout';

const Login = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [googleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const profile = useSelector(state => state.profile);

  const history = useHistory();

  useEffect(() => {
    clearLocalStorageOnLogout();
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleLogin = async (values, setSubmitting) => {
    const previousURL = localStorage.getItem('previousURL');

    try {
      const { data } = await login(values.email.toLowerCase(), values.password);
      disableLoading();

      const investorInformation = data?.user?.investors[0];

      const { phoneNumber, country = {} } = investorInformation || {};

      if (!phoneNumber || !country) {
        updateLoginState(data, props.login, props.profileAction, profile);
        history.push('/onboarding/contact-information');
      } else {
        if (previousURL) {
          history.push(previousURL);
          localStorage.removeItem('previousURL');
        }
        updateLoginState(data, props.login, props.profileAction, profile);
      }
    } catch (error) {
      disableLoading();
      setSubmitting(false);
      setAlert({
        alertMessage: error.response.data
          ? error.response.data.message
          : 'Unable to login.Try again.',
        alertMessageType: 'error',
      });
    }
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema(intl),
    onSubmit: async (values, { setSubmitting }) => {
      localStorage.removeItem('tokenValidated');
      enableLoading();
      handleLogin(values, setSubmitting);
    },
  });

  const handleSignupInGoogle = async () => {
    const cookies = cookiesDisabled();
    if (cookies.disabled) {
      setAlert({
        alertMessage: cookies?.errorMessage,
        alertMessageType: 'error',
      });
      return;
    }

    localStorage.setItem('googleSignInOrSignUp', googleAuth.GOOGLE_SIGN_IN);

    setGoogleAuthLoading(true);
    try {
      await handleGoogleSignUpAndSignIn();
      setGoogleAuthLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.message
          ? error?.message
          : 'Unable to login.Try again.',
        alertMessageType: 'error',
      });
      setGoogleAuthLoading(false);
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}

      <ExternalPageTitle
        title="Sign In"
        subTitle="Enter your details to access your account"
      />

      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {alert?.alertMessage ? (
          <div className="mb-10 ">
            <div className="text-center font-weight-bold text-red-500">
              {alert?.alertMessage}
              {alert?.alertMessage === errorMessages.VERIFY_EMAIL ? (
                <p>
                  <Link to={'/auth/resendVerification'}>
                    {' '}
                    Resend Confirmation email.
                  </Link>
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <CustomInput
          type="email"
          placeholder="Email"
          name="email"
          formik={formik}
          getInputClasses={getInputClasses}
          inputWidth={'auth-page-input'}
        />

        <PasswordInput
          viewPassword={viewPassword}
          formik={formik}
          setViewPassword={setViewPassword}
          name={'password'}
          placeholder="Password"
          passwordContainerSpacing={'mt-8 mb-7'}
        />

        <Link
          to="/auth/resetpassword"
          className="font-weight-600 text-md-right text-left text-hover-primary btn-mobile mt-3 mr-2"
          id="kt_login_forgot"
        >
          <p className="text-blue text-13">Forgot Password</p>
        </Link>

        <div className="d-flex flex-wrap justify-content-between align-items-center mobile-row">
          <ConfirmButton
            buttonType={'submit'}
            buttonText="Sign in"
            loading={loading}
            disable={formik.isSubmitting}
            buttonWidth={'w-100'}
            changeText={false}
          />
        </div>

        <div className="w-100 text-14 font-weight-500 text-center my-5 ">
          OR
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mobile-row">
          <CustomButton
            buttonText="Sign in with Google"
            buttonWidth={'w-100'}
            changeText={false}
            classes={'border-1-gray-350 bg-gray-450'}
            addBorder
            handleOnClick={handleSignupInGoogle}
            buttonIcon={'/media/logos/google.svg'}
            loading={googleAuthLoading}
            spinnerColor="spinner-black"
          />
        </div>

        <div className="mt-5">
          <DoYouHaveAccount
            question="Don't have an account?"
            action={'Sign up'}
            link={'/auth/select-sign-up-option'}
            margin="mt-n3"
          />
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...profile.actions })(Login),
);
