import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { actions as investorProfileActions } from '../../../Profile/_redux/profileRedux';

import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { goalInvestmentWithMPESA } from '../../../Goal/_redux/goalActions';
import { fundInvestmentWithMPESA } from '../../../Plan/_redux/plansActions';
import { paySubscriptionFeesWithMpesa } from '../../_redux/investmentAction';
import PhoneNumber from './PhoneNumber';
import { InvestmentAction } from '../../../../helpers/constants';
import Back from '../../../Common/BackArrow';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../../helpers/validatePhoneNumber';

const InvestmentType = {
  TOP_UP: 'TOP_UP',
  INITIAL_INVESTMENT: 'INITIAL_INVESTMENT',
};

const PayWithMobileMoney = ({
  setPayWithMobileMoney,
  amount,
  currency,
  pageTitle,
  setOpen,
  selectedCountry,
  promoCode,
  discountAmount,
}) => {
  const profile = useSelector(state => state.profile);
  const { investor } = profile || {};

  const [showCountDown, setShowCountDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const history = useHistory();
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false,
  );

  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );
  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );
  const subscriptionDetails = useSelector(state => state?.subscription);

  const dispatch = useDispatch();

  const check = () => {
    const value = localStorage.getItem('termsAndConditionsAccepted');
    setTermsAndConditionsAccepted(value);
  };

  useEffect(() => {
    check();
  }, []);

  const initialValues = {
    phoneNumber: investor?.phoneNumber
      ? parsePhoneNumber(investor?.phoneNumber).nationalNumber
      : '',
  };

  const PhoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.number().required('Phone number is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: PhoneNumberSchema,
    onSubmit: async () => {
      const phoneNumber = formik?.values?.phoneNumber;

      const fullPhoneNumber = getFullPhoneNumber(
        selectedCountry?.countryCallingCode,
        phoneNumber,
      );

      const isValid = validatePhoneNumber(fullPhoneNumber, phoneNumber);
      setValidPhoneNumber(isValid);

      if (!isValid) return;

      setLoading(true);
      setShowCountDown(true);
      try {
        // SEND PUSH NOTIFICATION ON MOBILE TO APPROVE PAYMENT
        const { TOP_UP, INITIAL_INVESTMENT } = InvestmentType;
        const {
          GOAL_INVESTMENT,
          GOAL_TOPUP,
          DIRECT_INVESTMENT,
          DIRECT_INVESTMENT_TOPUP,
          SUBSCRIPTION,
        } = InvestmentAction;

        const type = [GOAL_INVESTMENT, DIRECT_INVESTMENT].includes(
          investmentAction,
        )
          ? INITIAL_INVESTMENT
          : [GOAL_TOPUP, DIRECT_INVESTMENT_TOPUP].includes(investmentAction)
          ? TOP_UP
          : null;

        let response;
        switch (investmentAction) {
          case SUBSCRIPTION:
            response = await paySubscriptionFeesWithMpesa(
              Number(subscriptionDetails?.id),
              fullPhoneNumber,
              amount,
              promoCode,
            );
            break;
          case GOAL_INVESTMENT:
          case GOAL_TOPUP:
            response = await goalInvestmentWithMPESA(
              reduxGoalInvestmentDtls.goalId,
              fullPhoneNumber,
              convertAmountToNumber(reduxGoalInvestmentDtls?.amount),
              type,
              promoCode,
            );
            break;
          case DIRECT_INVESTMENT:
          case DIRECT_INVESTMENT_TOPUP:
            response = await fundInvestmentWithMPESA(
              reduxPlanInvestmentDtls.planId,
              fullPhoneNumber,
              convertAmountToNumber(reduxPlanInvestmentDtls?.amount),
              type,
              promoCode,
              { termsAndConditionsAccepted: termsAndConditionsAccepted },
            );
            break;
          default:
            alert('Invalid action');
            break;
        }

        setLoading(false);
        setShowCountDown(false);
        if (response.status === 201) {
          localStorage.removeItem('discountInfo');
          localStorage.removeItem('promoCode');

          if (investmentAction === SUBSCRIPTION) {
            dispatch(
              investorProfileActions.profileAction({
                ...profile,
                investor: {
                  ...investor,
                  subscription: {
                    ...investor?.subscription,
                    ...subscriptionDetails,
                  },
                },
              }),
            );

            history.push('/subscription');
          } else {
            history.push('/congratulations');
          }

          return;
        }
      } catch (error) {
        setShowCountDown(false);
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.errorMessage,
          alertMessageType: 'error',
        });
      }
    },
  });

  return (
    <>
      {pageTitle ? (
        <Back onClick={() => setPayWithMobileMoney(false)} title={pageTitle} />
      ) : null}
      <PhoneNumber
        setPayWithMobileMoney={setPayWithMobileMoney}
        amount={amount}
        currency={currency}
        validPhoneNumber={validPhoneNumber}
        country={selectedCountry}
        formik={formik}
        showCountDown={showCountDown}
        loading={loading}
        alertOn={alertOn}
        alert={alert}
        setOpen={setOpen}
        discountAmount={discountAmount}
      />
    </>
  );
};

export default PayWithMobileMoney;
