/* eslint-disable react/jsx-no-comment-textnodes */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorModal from '../../Common/ErrorModal';

import { useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { checkInvestorPermission } from '../../../helpers/checInvestorPermission';
import {
  FundType,
  InvestmentAction,
  ProfitType,
  accountTypes,
  investmentType,
  kycStatuses,
  operationType,
  pendingDisinvestmentErrorMessageOnTopUp,
  recurringPaymentModalOptions,
  recurringPaymentStatus,
  status,
} from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';
import {
  actions as pocketActions,
  initialState as pocketFlowInitialState,
} from '../../Pocket/_redux/reducers';
import { actions as withdrawalActions } from '../_redux/portfolioReducer';

import { messages } from '../../../utils/messages';
import { ApproveRecurringPaymentModal } from '../../Common/ApproveRecurringPaymentModal';
import Back from '../../Common/BackArrow';
import { CancelRecurringPaymentModal } from '../../Common/CancelRecurringPaymentModal';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorCard from '../../Common/ErrorCard';
import Card from '../../Common/FundCard/Card';
import LeftContainer from '../../Common/FundCard/LetfContainer';
import RigthContainer from '../../Common/FundCard/RigthContainer';
import MessageAlert from '../../Common/MessageAlert';
import { PermissionModalContent } from '../../Common/PermissionModalContent';
import PopoverComponent from '../../Common/PopoverComponent';
import transactionPopup from '../../Common/Popup/transactionPopup';
import {
  CancelButton,
  ConfirmButton,
  TextButton,
} from '../../Common/ReusableButtons';
import SelectListModal from '../../Common/SelectListModal';
import { actions as planReducerActions } from '../../Plan/_redux/planReducers';
import {
  withdrawWithAmount,
  withdrawWithPercentage,
} from '../../Plan/_redux/plansActions';
import SavingAccountMesssage from '../../Plan/components/SavingAccoutMessage';
import GoalDetails from '../../Portfolio/components/GoalDetails';
import WithdrawAmountModal from '../../Portfolio/components/WithdrawAmountModal';
import { updateInvestorRecurringPaymentStatus } from '../../RecurringPayments/_redux/recurringPaymentAction';
import * as recurringPaymentDataState from '../../RecurringPayments/_redux/recurringPaymentRedux';
import { fetchSingleInvestment } from '../_redux/directInvestmentsActions';
import AmountBasedWithdrawModal from '../components/AmountBasedWithdrawModal';
import { PlanOrGoalInvestmentDetails } from '../components/PlanOrGoalINvestmentDetails';
import RecurringPayments from '../components/RecurringPayments';
import RecurringPaymentsData from '../components/RecurringPaymentsData';
import { TableData } from '../components/TableData';
import { TableHeader } from '../components/TableHeader';
import { AdditionalInformationRequiredContent } from '../../Plan/components/AdditionalInformationRequiredContent';
import { handleKycRouting } from '../../../helpers/handleKycRouting';

const ViewInvestment = props => {
  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const planDetails = useSelector(state => state.plan.viewedPlan);
  const [
    openRecurringPaymentSetUpModal,
    setOpenRecurringPaymentModal,
  ] = useState(false);
  const [
    openApproveRecurringPaymentModal,
    setOpenApproveRecurringPaymentModal,
  ] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [investments, setInvestments] = useState({});
  const { planSubscriptionId } = props.match.params;
  const [withdrawLoading, setWithDrawLoading] = useState();
  const [open, setOpen] = useState(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);
  const [openAmountWithdrawModal, setOpenAmountWithdrawModal] = useState(false);
  const [withdrawPercentage, setwithdrawPercentage] = useState(0);
  const [withdrawalAmount, setwithdrawalAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [withdrawalError, setWithdrawalError] = useState(null);
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [openKycReminderModal, setOpenKycReminderModal] = useState(false);
  const [errorModalContent, setErrorModalContent] = useState();

  const pocketFlow = useSelector(state => state.pocketFlow);

  const { investor, user } = useSelector(state => state.profile);
  const withdrawalDetails = useSelector(state => state.withdrawalDetails);

  const [show, setShow] = useState(false);
  const { kycLevel, pendingKyc, kycStatus, investorType } = investor;
  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const [recurringPaymentExists, setRecurringPaymentExists] = useState(false);
  const [
    openCancelRecurringPaymentModal,
    setOpenCancelRecurringPaymentModal,
  ] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [
    cancelRecurringPaymentSuccess,
    setCancelRecurringPaymentSuccess,
  ] = useState(false);

  const handleOpenModal = () => {
    setOpenRecurringPaymentModal(true);
  };
  const handleApproveRecurringPayment = () => {
    setOpenApproveRecurringPaymentModal(true);
  };
  const handleEditRecurringPaymentModal = () => {
    setOpenCancelRecurringPaymentModal(!openCancelRecurringPaymentModal);
  };
  const cancelRecurringPayment = async status => {
    const { id } = investments?.recurringPayment;
    setLoading(true);
    setOpenCancelRecurringPaymentModal(false);
    try {
      const res = await updateInvestorRecurringPaymentStatus(id, {
        status,
      });
      if (res.status === 200) {
        setCancelRecurringPaymentSuccess(true);
        setShow(false);
      }
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      setAlertOn(true);
      setLoading(false);
    }
  };

  const [completeKyc, setCompleteKyc] = useState(false);

  const [state, setState] = useState({
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        colors: ['#0071CE', '#FF7F30', '#546E7A', '#E91E63', '#FF9800'],
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });

  const fetchInvestment = async () => {
    try {
      setLoading(true);
      const res = await fetchSingleInvestment(planSubscriptionId);
      if (res.status === 200) {
        const fund = res.data.data;
        setInvestments(fund);
        const { plan } = fund;

        dispatch(planReducerActions.viewedPlan(plan)); //save plan to redux

        if (fund?.recurringPayment) {
          setRecurringPaymentExists(true);
        }
        const transaction = fund.transactions.find(
          item =>
            item.transactionStatus === status.PENDING &&
            item.operationType === operationType.WITHDRAW,
        );

        if (transaction) {
          setWithdrawalError({
            alertMessage: messages.fundWithdraw.unpprovedWithdraw,
            alertMessageType: 'alert-light-danger',
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestment();
    resetPlanInvestmentDtls();

    dispatch(pocketActions.updatePocketFlow(pocketFlowInitialState));
  }, []);

  const resetPlanInvestmentDtls = () => {
    dispatch(planReducerActions.ResetPlanInvestmentDetails());
  };

  const handleSelect = item => {
    if (item) {
      setOpenRecurringPaymentModal(false);
      dispatch(
        recurringPaymentDataState?.actions.updateRecurringPaymentData({
          paymentChannel: item,
          planId: parseInt(investments?.plan?.id),
          planCurrency: investments?.plan?.currency,
          planName: investments?.plan?.coolName,
          planSubscriptionId: investments?.id,
        }),
      );
      history.push('/recurring-payments');
    }
  };

  const handleApproveRecurringPaymentAction = async () => {
    const { id } = investments?.recurringPayment;
    setLoading(true);
    setOpenApproveRecurringPaymentModal(false);
    try {
      const res = await updateInvestorRecurringPaymentStatus(id, {
        status: recurringPaymentStatus.APPROVED,
      });
      if (res.status === 200) {
        // fetch the data again
        fetchInvestment();
        resetPlanInvestmentDtls();
        dispatch(pocketActions.updatePocketFlow(pocketFlowInitialState));
      }
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      setAlertOn(true);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  const {
    transactions,
    investmentDetails,
    sector,
    avgYTD,
    withdrawPendingAmount,
    totalWithdrawnAmount,
    depositPendingTransferAmount,
    depositReceivedAmount,
    plan,
  } = investments || {};
  /*
   * Open withdraw modal
   *
   * 1.  If the fundPortfolio value is less than the required withdrawable amount, withdraw 100% of the fund
   *       if not, then the modal will open and user can set required percentage.
   * 2. If the plan being withdrawn is partialWithdrawable=FALSE, don't open modal and withdraw 100% of the fund
   */

  const openWithdrawModalOrConfirmation = async () => {
    const updateDisinvestmentDetails = () => {
      const withdrawalDetails = {
        unitPercentage: 100,
        numberOfUnits: investmentDetails?.totalUnits,
        transactionFee: 0,
        approximatedValue: investmentDetails?.fundPortfolioValue,
      };

      dispatch(withdrawalActions.updateWithdrawalDetails(withdrawalDetails));
    };

    if (planDetails?.withdrawInMultiples || planDetails?.isMoneyMarketFund) {
      setOpenAmountWithdrawModal(true);
    } else {
      if (!planDetails?.partialWithdrawable) {
        updateDisinvestmentDetails();

        setOpen(true);
      } else if (
        investmentDetails?.fundPortfolioValue <
        planDetails?.minWithdrawableAmount
      ) {
        updateDisinvestmentDetails();
        setOpen(true);
      } else {
        setOpenWithdrawalModal(true);
      }
    }
  };

  const handleWithdrawals = async () => {
    setWithDrawLoading(true);
    try {
      let response;
      if (planDetails?.withdrawInMultiples || planDetails?.isMoneyMarketFund) {
        response = await withdrawWithAmount(
          planSubscriptionId,
          withdrawalAmount,
        );
      } else {
        response = await withdrawWithPercentage(
          planSubscriptionId,
          withdrawalDetails?.unitPercentage,
        );
      }

      if (response.status === 201) {
        dispatch(withdrawalActions.resetWithdrawalDetails());

        setAlertOn(true);
        setAlert({
          alertMessage: `Your disinvestment request is received and is being processed.`,
          alertMessageType: 'success',
        });
        setOpen(false);
        setWithDrawLoading(false);
        fetchInvestment();
      }
    } catch (err) {
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
      setWithDrawLoading(false);
      setOpen(false);
    }
  };

  const renderAmount = transaction => {
    if (transaction.operationType === operationType.DEPOSIT) {
      return (
        <TableData
          value={currencyHelper(
            transaction.convertedFinalAmount,
            transaction.finalCurrency,
          )}
        />
      );
    } else {
      const content = (
        <div>{messages?.approximativeWithdrawnAmountInTransaction}</div>
      );
      return (
        /* approximative untill block is completed. */
        <TableData
          value={
            <>
              {currencyHelper(
                transaction.convertedFinalAmount,
                transaction.finalCurrency,
              )}
              {transaction.transactionStatus !== status.COMPLETED ? (
                <span
                  style={{
                    fontWeight: 500,
                    color: '#0071CE',
                    marginLeft: '2px',
                  }}
                >
                  <PopoverComponent popoverContent={content}>
                    <span
                      className="cursor-pointer"
                      style={{ marginLeft: '5px' }}
                    >
                      <i
                        style={{ color: '#0071CE', fontSize: '16px' }}
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </PopoverComponent>
                </span>
              ) : null}
            </>
          }
        />
      );
    }
  };
  const { pathname } = history.location;

  const displayStatus = (transactionStatus, transactionOperation) => {
    if (
      (transactionStatus === status.RECONCILED ||
        transactionStatus === status.PENDING) &&
      transactionOperation === operationType.DEPOSIT
    ) {
      return 'Deposit Received';
    } else {
      return capitalizeTheFirstLetterOfEachWord(
        transactionStatus?.replace('_', ' '),
      );
    }
  };

  const handleRedirect = () => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'investment',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    if (
      investments?.withdrawPendingAmount &&
      investments?.withdrawPendingAmount > 0
    ) {
      setErrorModalContent(pendingDisinvestmentErrorMessageOnTopUp);
      setOpenErrorModal(true);
      return;
    }

    localStorage.removeItem('promoCode');
    localStorage.removeItem('subscriptionId');
    localStorage.removeItem('goalDiscountInfo');
    localStorage.removeItem('discountInfo');
    localStorage.setItem('pathName', pathname);

    dispatch(
      pocketActions?.updatePocketFlow({
        ...pocketFlow,
        type: { label: 'Invest', path: 'investment' },
        investmentType: investmentType.TOP_UP,
      }),
    );

    dispatch(
      investmentReducerActions.updateInvestmentAction(
        InvestmentAction.DIRECT_INVESTMENT_TOPUP,
      ),
    );

    if (withdrawalError) {
      return setShowWithdrawalError(true);
    } else {
      localStorage.setItem('investingPageEntryPoint', pathname);

      history.push(`/plans/topup/${planSubscriptionId}`, {
        backUrl: pathname,
      });
    }
  };

  const modalContent = <>{alert?.alertMessage}</>;

  const handDisinvestButton = () => {
    setCompleteKyc(false);
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'disinvestment',
    );

    if (!investorHasPermissionToInvest) {
      setCompleteKyc(true);
      setShow(true);
      return;
    }

    if (
      withdrawPendingAmount !== 0 ||
      investmentDetails?.fundPortfolioValue === 0
    ) {
      setErrorModalContent(
        withdrawPendingAmount !== 0
          ? 'Another withdraw transaction has already been requested and is in progress.'
          : 'You can not make a withdraw request on this fund as it has a 0 portfolio value',
      );
      setOpenErrorModal(true);
    } else {
      setOpenErrorModal(false);
      openWithdrawModalOrConfirmation();
    }
  };

  const planIsNotInvestable = !planDetails?.settings?.[0]?.investable;
  const { nonInvestableNote } = planDetails?.settings?.[0] || {};
  const disableInvestButton = planIsNotInvestable;

  const theFundHasPendingTransactions =
    transactions &&
    transactions.some(
      transaction =>
        transaction.transactionStatus === status.PENDING ||
        transaction.transactionStatus === status.TRANSFER_INCOMPLETE,
    );

  const withdrawTheWholeAmount =
    investmentDetails?.fundPortfolioValue < planDetails?.minWithdrawableAmount;

  const minWithdrawableAmount = withdrawTheWholeAmount
    ? investmentDetails?.fundPortfolioValue
    : planDetails?.minWithdrawableAmount;

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : (
        <>
          {openAmountWithdrawModal ? (
            <AmountBasedWithdrawModal
              open={openAmountWithdrawModal}
              setOpenAmountWidthdrawModal={setOpenAmountWithdrawModal}
              title={messages.fundWithdraw.title}
              fundValue={
                plan.isMoneyMarketFund
                  ? investmentDetails?.fundPortfolioValue
                  : investmentDetails?.investedAmount
              }
              totalUnits={investmentDetails?.totalUnits}
              currency={planDetails?.currency}
              minWithdrawableAmount={minWithdrawableAmount}
              multiplesWithdrawableAmount={
                planDetails?.multiplesWithdrawableAmount
              }
              setwithdrawalAmount={setwithdrawalAmount}
              setOpenConfirmModal={setOpen}
              handleWithdrawals={handleWithdrawals}
              withdrawTheWholeAmount={withdrawTheWholeAmount}
            />
          ) : (
            <WithdrawAmountModal
              open={openWithdrawalModal}
              setOpenWithdrawalModal={setOpenWithdrawalModal}
              title={messages.fundWithdraw.title}
              fundValue={investmentDetails?.fundPortfolioValue}
              totalUnits={investmentDetails?.totalUnits}
              setwithdrawPercentage={setwithdrawPercentage}
              setOpenConfirmModal={setOpen}
              handleWithdrawals={handleWithdrawals}
              openModal={open}
              withdrawTheWholeAmount={
                investmentDetails?.fundPortfolioValue <
                planDetails?.minWithdrawableAmount
              }
              planDetails={planDetails}
            />
          )}
          <Back
            text="Back to portfolio"
            onClick={() => {
              history.push('/portfolio');
            }}
          />
          {planDetails?.settings?.[0]?.viewable ? (
            <div className="py-5 mb-3 internal-container d-flex justify-content-between font-weight-bold">
              <span className="mt-2"></span>
              <ConfirmButton
                buttonText={'View Fund'}
                handleOnClick={() => {
                  localStorage.setItem('pathName', pathname);

                  localStorage.setItem(
                    'planEntryPointUrl',
                    history.location.pathname,
                  );

                  history.push(`/plans/${planDetails?.id}`);
                }}
                buttonWidth={'width-140'}
              />
            </div>
          ) : (
            ''
          )}
          {alertOn &&
            (alert.alertMessageType === 'success' ? (
              <CommonSuccessModal
                open={alertOn}
                setOpen={() => setAlertOn(false)}
                content={modalContent}
                moreClasses={'bg-white'}
                handleButtonClick={() => {
                  setAlertOn(false);
                }}
                addCloseButton={true}
              />
            ) : (
              <ErrorModal
                backgroundColor="bg-orange-100"
                open={alertOn}
                content={modalContent}
                setOpen={setAlertOn}
              />
            ))}
          <Card>
            <LeftContainer
              fundBackground={planDetails?.imageUrl}
              sector={sector}
              imageBackground="fund-image-backgound"
            />
            <RigthContainer
              name={planDetails?.name}
              coolName={planDetails?.coolName}
              classes="flex-column"
              classNames="height-goal"
            >
              <GoalDetails
                classes="d-flex flex-column justify-content-between p-5 w-100"
                averageYtd={avgYTD}
                currency={planDetails?.currency}
                transactions={transactions}
                riskLevel={investmentDetails?.risk}
                plan={planDetails}
              />

              <div className="w-100 position-absolute fixed-bottom z-index-1  px-5 mb-7">
                <div className="d-flex justify-content-between flex-wrap buttons-gap">
                  <CancelButton
                    buttonText="Disinvest"
                    handleOnClick={() => handDisinvestButton()}
                    loading={loading}
                    twinButton
                  />

                  {planDetails && (
                    <ConfirmButton
                      buttonText={
                        !planIsNotInvestable ? 'Top Up' : 'Not Investable'
                      }
                      handleOnClick={() =>
                        kycStatus === kycStatuses.PENDING
                          ? setOpenKycReminderModal(true)
                          : handleRedirect()
                      }
                      disable={disableInvestButton}
                      loading={loading}
                      twinButton
                    />
                  )}
                </div>

                {planIsNotInvestable && nonInvestableNote ? (
                  <div className="text-left mt-5">
                    <strong>Note: </strong>
                    {nonInvestableNote}
                  </div>
                ) : null}

                {showWithdrawalError && (
                  <>
                    {' '}
                    <br />
                    <MessageAlert
                      closeAlert={() => setShowWithdrawalError(false)}
                      alert={withdrawalError}
                    />
                  </>
                )}
              </div>
            </RigthContainer>
          </Card>
          {planDetails?.lockInPeriod ? (
            <SavingAccountMesssage
              message={messages.savingFundAlert}
              classes="mt-5 mb-2"
            />
          ) : null}
          <div className="px-7 pb-7 mt-5 border-radius-8 investment-summary">
            <div className="my-5" />
            <div className="text-center">
              <div className="plan-subscription-details-container text-dark">
                {recurringPaymentExists === false && (
                  <>
                    <RecurringPaymentsData
                      setOpenRecurringPaymentModal={() => handleOpenModal()}
                      recurringPaymentData={
                        investments?.recurringPayment &&
                        investments?.recurringPayment
                      }
                    />
                    <div className="blue-divider-custom" />
                  </>
                )}

                {investments?.recurringPayment &&
                  investments?.recurringPayment?.status ===
                    recurringPaymentStatus.PENDING && (
                    <>
                      <RecurringPaymentsData
                        setOpenRecurringPaymentModal={() => handleOpenModal()}
                        recurringPaymentData={
                          investments?.recurringPayment &&
                          investments?.recurringPayment
                        }
                        onClick={handleApproveRecurringPayment}
                      />
                      <div className="blue-divider-custom" />
                    </>
                  )}

                <PlanOrGoalInvestmentDetails
                  label={
                    planDetails?.fundType === FundType.SAVE
                      ? 'Total Saved'
                      : 'Total Invested'
                  }
                  value={
                    investmentDetails?.investedAmount ||
                    investmentDetails?.investedAmount === 0
                      ? currencyHelper(
                          investmentDetails?.investedAmount,
                          planDetails?.currency,
                        )
                      : '---'
                  }
                  hasPendingTransactions={theFundHasPendingTransactions}
                  addInformationIcon={true}
                  popOverMessage={
                    planDetails?.fundType === FundType.SAVE
                      ? messages.pendingTransactions.totalInvested
                      : messages.pendingTransactions.totalInvested
                  }
                />

                <PlanOrGoalInvestmentDetails
                  label={'Current Value'}
                  value={
                    investmentDetails?.fundPortfolioValue ||
                    investmentDetails?.fundPortfolioValue === 0
                      ? currencyHelper(
                          investmentDetails?.fundPortfolioValue,
                          planDetails?.currency,
                        )
                      : '---'
                  }
                  hasPendingTransactions={theFundHasPendingTransactions}
                  addInformationIcon={true}
                  popOverMessage={messages.pendingTransactions.currentValue}
                />

                <PlanOrGoalInvestmentDetails
                  label={'Disinvested Amount'}
                  value={
                    totalWithdrawnAmount || totalWithdrawnAmount === 0
                      ? currencyHelper(
                          totalWithdrawnAmount,
                          planDetails?.currency,
                        )
                      : '---'
                  }
                  addInformationIcon={false}
                />

                {withdrawPendingAmount === 0 && depositReceivedAmount !== 0 ? (
                  <PlanOrGoalInvestmentDetails
                    label={'Deposit Received'}
                    value={currencyHelper(
                      depositReceivedAmount,
                      planDetails?.currency,
                    )}
                    addInformationIcon={false}
                  />
                ) : null}

                {withdrawPendingAmount === 0 &&
                depositPendingTransferAmount !== 0 ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Payment Instructions Received'}
                      value={currencyHelper(
                        depositPendingTransferAmount,
                        planDetails?.currency,
                      )}
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                {withdrawPendingAmount > 0 ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Pending Disinvestment'}
                      value={currencyHelper(
                        withdrawPendingAmount,
                        planDetails?.currency,
                      )}
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                <PlanOrGoalInvestmentDetails
                  label={'Profit'}
                  value={
                    investmentDetails?.margin
                      ? `${investmentDetails?.margin.toFixed(2)}%`
                      : investmentDetails?.margin === 0
                      ? '0%'
                      : '---'
                  }
                  addInformationIcon={false}
                />

                {planDetails?.profitType === ProfitType.VARIABLE &&
                planDetails?.fundType !== FundType.SAVE &&
                !planDetails?.isMoneyMarketFund ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Units'}
                      value={
                        investmentDetails?.totalUnits
                          ? investmentDetails?.totalUnits.toFixed(4)
                          : investmentDetails?.totalUnits === 0
                          ? '0'
                          : '---'
                      }
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                {recurringPaymentExists &&
                  investments?.recurringPayment?.status !==
                    recurringPaymentStatus.PENDING && (
                    <RecurringPayments
                      id={planDetails.id}
                      recurringPaymentData={investments?.recurringPayment}
                      onClick={handleEditRecurringPaymentModal}
                      paymentChannel={
                        investments?.recurringPayment?.paymentChannel
                      }
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="card blue-border mt-3">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <TableHeader header={'Date'} maxWidth="110px" />
                      <TableHeader header={'Amount'} maxWidth="150px" />
                      <TableHeader
                        header={'ndovu One-off Fee'}
                        maxWidth="160px"
                      />
                      {planDetails?.fundType === FundType.SAVE &&
                      !planDetails?.isMoneyMarketFund ? (
                        <TableHeader
                          header={'Lockout Date'}
                          maxWidth={'120px'}
                        />
                      ) : null}
                      <TableHeader header={'Type'} />
                      {investor?.investorType !== accountTypes.INDIVIDUAL ? (
                        <>
                          <TableHeader header={'Actioned By'} />
                          <TableHeader header={'Email'} />
                        </>
                      ) : null}
                      <TableHeader header={'Status'} maxWidth={'180px'} />
                      <TableHeader
                        header={'Trigger Reason'}
                        maxWidth={'180px'}
                      />
                    </tr>
                  </thead>

                  <tbody>
                    {transactions &&
                      transactions.map(transaction => (
                        <tr key={transaction.id}>
                          <TableData
                            value={moment(transaction.createdAt).format(
                              'YYYY-MM-DD',
                            )}
                          />
                          {/* render transaction amounts */}
                          {renderAmount(transaction)}

                          <TableData
                            value={
                              transaction.operationType ===
                                operationType.DEPOSIT &&
                              (transaction.ndovuFee ||
                                transaction.ndovuFee === 0)
                                ? currencyHelper(
                                    transaction.ndovuFee,
                                    transaction.originalCurrency,
                                  )
                                : '--'
                            }
                          />

                          {planDetails?.fundType === FundType.SAVE &&
                          !planDetails?.isMoneyMarketFund ? (
                            <TableData
                              value={
                                transaction.lockOutDate
                                  ? transaction.lockOutDate
                                  : '---'
                              }
                            />
                          ) : null}

                          <TableData
                            value={capitalizeTheFirstLetterOfEachWord(
                              transaction.operationType,
                            )}
                          />

                          {investor?.investorType !==
                          accountTypes.INDIVIDUAL ? (
                            <>
                              <TableData
                                value={
                                  transaction.initiatedBy ? (
                                    <>
                                      {transaction.initiatedBy?.firstName}{' '}
                                      {transaction.initiatedBy?.lastName}
                                    </>
                                  ) : (
                                    '---'
                                  )
                                }
                              />

                              <TableData
                                value={transaction.initiatedBy?.email}
                              />
                            </>
                          ) : null}

                          <TableData
                            value={displayStatus(
                              transaction?.transactionStatus,
                              transaction?.operationType,
                            )}
                          />

                          <TableData
                            value={
                              transaction?.triggerReason
                                ? transaction.triggerReason
                                : '---'
                            }
                          />

                          {planDetails &&
                          transaction.operationType ===
                            operationType.WITHDRAW &&
                          planDetails.fundType === FundType.SAVE ? (
                            <td></td>
                          ) : (
                            <TableData
                              value={
                                <PopoverComponent
                                  popoverContent={transactionPopup(
                                    transaction,
                                    planDetails,
                                  )}
                                >
                                  <span style={{ cursor: 'pointer' }}>
                                    <i
                                      style={{
                                        color: '#0071CE',
                                      }}
                                      className="ki ki-bold-more-hor"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </PopoverComponent>
                              }
                            />
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {false && (
              <div className="card card-custom gutter-b p-md-20">
                <div id="chart">
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={300}
                  />
                </div>
              </div>
            )}{' '}
          </div>
        </>
      )}
      {openErrorModal && (
        <ErrorModal
          backgroundColor="bg-orange-100"
          open={openErrorModal}
          content={errorModalContent}
          setOpen={setOpenErrorModal}
        />
      )}
      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent />}
        moreClasses={'bg-white'}
        handleButtonClick={() => setShow(false)}
        title={'Notification'}
        addCloseButton={true}
      />

      <CommonSuccessModal
        open={cancelRecurringPaymentSuccess}
        setOpen={() => setCancelRecurringPaymentSuccess(false)}
        content={<>You have successfully cancelled recurring investment.</>}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          setCancelRecurringPaymentSuccess(false);
          window.location.reload();
        }}
        title={'Cancelled'}
        changeText={false}
      />

      <ApproveRecurringPaymentModal
        open={openApproveRecurringPaymentModal}
        setOpen={() => setOpenApproveRecurringPaymentModal(false)}
        content={<>Do You want to approve this?</>}
        moreClasses={'bg-white'}
        handleButtonClick={handleApproveRecurringPaymentAction}
      />

      <SelectListModal
        open={openRecurringPaymentSetUpModal}
        handleClose={() => setOpenRecurringPaymentModal(false)}
        items={recurringPaymentModalOptions}
        handleSelect={handleSelect}
        name={'sourceOfFunds'}
      />

      <CancelRecurringPaymentModal
        open={openCancelRecurringPaymentModal}
        setOpen={handleEditRecurringPaymentModal}
        handleButtonClick={() =>
          cancelRecurringPayment(recurringPaymentStatus.CANCELLED)
        }
        addCloseButton={handleEditRecurringPaymentModal}
        handleCancelButton={handleEditRecurringPaymentModal}
        title="Cancel"
      />

      <CommonSuccessModal
        open={openKycReminderModal}
        setOpen={() => setOpenKycReminderModal(false)}
        content={<AdditionalInformationRequiredContent />}
        moreClasses={'bg-white'}
        addTitle={false}
        iconName={'folder'}
        buttons={
          <div className="d-flex flex-column mx-auto mt-5">
            <ConfirmButton
              buttonText={'Next'}
              loading={loading}
              handleOnClick={() => {
                localStorage.setItem(
                  'kycEntryPointUrl',
                  `/portfolio/viewInvestment/${planSubscriptionId}`,
                );

                setOpenKycReminderModal(false);

                history.push(
                  handleKycRouting(kycChecks, '/manage-account', investorType),
                );
              }}
              classes={'mx-auto'}
            />

            <TextButton
              buttonText={'Skip'}
              handleButtonClick={() => {
                setOpenKycReminderModal(false);
                handleRedirect();
              }}
            />
          </div>
        }
      />
    </>
  );
};

export default ViewInvestment;
