import jwtDecode from 'jwt-decode';

const checkToken = () => {
  let isTokenValid = false;

  const token = localStorage.getItem('ndovuToken');
  if (token && token !== null && token !== undefined && token !== 'undefined') {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem('ndovuToken');
      isTokenValid = false;
    }

    isTokenValid = true;
  }

  const { pathname } = window.location;

  if (
    !isTokenValid &&
    !pathname.includes('auth') &&
    !pathname.includes('error')
  )
    localStorage.setItem('previousURL', pathname);

  return { isTokenValid };
};

const isTokenValidated = () => {
  const isValidated = localStorage.getItem('tokenValidated');
  if (isValidated && isValidated === 'true') {
    return true;
  } else {
    return false;
  }
};

export { checkToken, isTokenValidated };
